<template>
    <el-dialog :visible.sync="visible" title="自定义列表">
        <table>
            <colgroup>
                <col width="80px" />
            </colgroup>
            <tbody>
                <tr>
                    <td>显示列名</td>
                    <td id="show">
                        <el-tag
                            @close="closeTag('hide', item)"
                            v-for="item in showColumns"
                            :data-prop="item[tagKey]"
                            :key="item[tagKey]"
                            :closable="!banList.includes(item[tagKey])"
                            :class="{ banDrag: banList.includes(item[tagKey]) }"
                        >
                            {{ item[tagTitle] }}
                        </el-tag>
                    </td>
                </tr>
                <tr>
                    <td>隐藏列名</td>
                    <td id="hide">
                        <el-tag @close="closeTag('show', item)" closable v-for="item in unShowColumns" :key="item[tagKey]" :data-prop="item[tagKey]">
                            {{ item[tagTitle] }}
                        </el-tag>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="handle_buttons">
            <el-button type="primary" @click="determine">确定</el-button>
            <el-button @click="visible = false">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
    /**
     * 根据定义的字段显示自定义列表
     */
    import Sortable from "sortablejs";
    import { cloneDeep } from "lodash";
    export default {
        props: {
            banList: {
                type: Array,
                default: () => []
            },
            tagTitle: {
                type: String,
                default: "title"
            },
            tagKey: {
                type: String,
                default: "prop"
            }
        },
        data() {
            return {
                visible: false,

                showList: [], // 当前显示的列表
                allList: [] // 所有的列
            };
        },
        computed: {
            showColumns() {
                if (this.showList && this.showList.length) {
                    let columns = [];
                    this.showList.forEach(v => {
                        const itemIndex = this.allList.findIndex(column => column[this.tagKey] == v);
                        if (itemIndex != -1) {
                            columns.push(this.allList[itemIndex]);
                        }
                    });
                    return columns;
                } else {
                    return [];
                }
            },
            unShowColumns() {
                if (this.showList && this.allList && this.showList.length == this.allList.length) {
                    return [];
                } else if (this.allList) {
                    return this.allList.filter(o => {
                        return !this.showList.includes(o[this.tagKey]);
                    });
                } else {
                    return [];
                }
            }
        },
        created() {
            this.initShowList();
        },
        methods: {
            setCoverList(list) {
                if (Array.isArray(list)) {
                    this.showList = list;
                }
                localStorage.setItem(window.config.system + this.$route.path, JSON.stringify(this.showList));
            },
            initShowList() {
                const listStr = localStorage.getItem(window.config.system + this.$route.path);
                if (listStr) {
                    this.showList = JSON.parse(listStr);
                } else {
                    if (this.allList && this.allList.length) {
                        const props = this.allList.map(v => v[this.tagKey]);
                        this.setCoverList(props);
                    }
                }
            },
            closeTag(action, tag) {
                if (action == "hide") {
                    // 隐藏
                    const kIndex = this.showList.findIndex(v => v == tag[this.tagKey]);
                    this.showList.splice(kIndex, 1);
                } else {
                    // 显示
                    this.showList.push(tag[this.tagKey]);
                }
            },
            init(allList) {
                if (Array.isArray(allList) && allList.length) {
                    this.allList = cloneDeep(allList);
                    this.initShowList();

                    console.log(JSON.stringify(allList));

                    const _this = this;

                    this.$nextTick(() => {
                        const showEl = document.getElementById("show");
                        const showSort = new Sortable(showEl, {
                            animation: 150,
                            draggable: "span",
                            sort: true,
                            group: "tag",
                            filter: ".banDrag",
                            dataIdAttr: "data-prop",
                            onEnd: function () {
                                _this.showList = showSort.toArray();
                            },
                            onAdd: function () {
                                _this.showList = showSort.toArray();
                            },
                            onRemove: function () {
                                _this.showList = showSort.toArray();
                            }
                        });
                        const hideEl = document.getElementById("hide");
                        new Sortable(hideEl, {
                            animation: 150,
                            draggable: "span",
                            sort: true,
                            group: "tag",
                            dataIdAttr: "data-prop"
                        });
                    });

                    this.visible = true;
                } else {
                    this.$message.warning({
                        message: "请传入所有列属性"
                    });
                }
            },
            determine() {
                this.setCoverList();
                location.reload();
            }
        }
    };
</script>
<style lang="scss" scoped>
    table,
    table tr th,
    table tr td {
        border: 1px solid #dcdcdc;
    }
    table tr {
        min-height: 60px;
    }
    table {
        border-collapse: collapse;
        padding: 2px;
    }
    .el-tag {
        margin: 5px;
        cursor: pointer;
    }
    .handle_buttons {
        text-align: center;
        margin-top: 10px;
    }
</style>
